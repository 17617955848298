<template>
	<div class="field">
		<label for="message">{{ label }}
			<!-- {{ activeMask }} -->

			<input type="text"
				v-if="activeMask"
				v-mask="maske"
				:value="modelValue"
				@input="$emit('update:modelValue', $event.target.value)"
				placeholder="+38(0__) ___-__-__"
			>
			<input v-else
				type="text"
				:value="modelValue"
				@input="$emit('update:modelValue', $event.target.value)"
			>
		</label>
	</div>
</template>
<script>
import {mask} from 'vue-the-mask'
export default {
  name: 'Input',
	directives: {mask},
  props: {
		maske: {
			type: String
		},

		label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      // required: true
    }
  },
	data() {
		return {
			textarea: ''
		}
	},
	emits: ['update:modelValue'],
	watch: {

	},
	computed: {
		activeMask() {
			return this.maske ? this.maske.length > 0 ? true : false : false
		}
	}

}
</script>
<style lang="sass" scoped>
label
	font-size: 0.85em
	line-height: 1.2
	font-weight: 600
	span
		display: block
		color: #5a667b
textarea,
input[type=text]
	display: block
	width: 100%
	border: 1px solid #ecf0f1
	background-color: #ecf0f1
	border-radius: 5px
	padding: 10px
	margin-top: 5px
	color: #2c3e50
textarea:active, textarea:focus,
input[type=text]:active,
input[type=text]:focus
	outline: none
	background-color: #fff
textarea
	overflow-y: hidden
</style>