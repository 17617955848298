<template>
	<div class="field">
		<label for="message">{{ label }}
			<textarea :value="modelValue" @click="focusMe" @input="$emit('update:modelValue', $event.target.value)" name="message" ref="textarea" rows="3"></textarea>
		</label>
	</div>
</template>
<script>
export default {
  name: 'Textarea',
  props: {

		label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      // required: true
    }
  },
	data() {
		return {
			textarea: ''
		}
	},
	emits: ['update:modelValue'],
	mounted() {
		this.$nextTick(()=>{
			this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
	});
	},
	methods: {
		focusMe() {

		}
	},
	watch: {


		modelValue: function(){
			this.$refs.textarea.style.height="100%";
			this.$nextTick(() => {
				this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
			})
		},

	}
}
</script>
<style lang="sass" scoped>
label
	font-size: 0.85em
	line-height: 1.2
	font-weight: 600
	span
		display: block
		color: #5a667b
textarea,
input[type=text]
	display: block
	width: 100%
	border: 1px solid #ecf0f1
	background-color: #ecf0f1
	border-radius: 5px
	padding: 10px
	margin-top: 5px
	color: #2c3e50
textarea:active, textarea:focus,
input[type=text]:active,
input[type=text]:focus
	outline: none
	background-color: #fff
textarea
	overflow-y: hidden
.field
	margin-bottom: 35px
</style>