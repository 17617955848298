<template>
	<div>
		<div class="block block-3">

			<div class="formbox">
				<div class="formbox-title">
					Якщо Ви бажаєте, щоб з Вами зв'язались у зв'язку з Вашим відгуком, будь ласка, вкажіть Ваші контактні дані
				</div>
				<InputBlock label="Ваше Ім'я" v-model="formData.name" ></InputBlock>
				<InputBlock label="Ваш телефон" v-model="formData.phone" :value="formData.phone" maske="+38(0##) ###-##-##"></InputBlock>
				<!-- <Textarea label="Ваш телефон" v-model="formData.phone" ></Textarea> -->
				<InputBlock label="Ваш E-mail" v-model="formData.email" ></InputBlock>

				<div class="buttongroup">
					<button @click="stepPrevius">назад</button>
					<button @click="validateStep">відправити</button>
				</div>



			</div>

		</div>




	</div>
</template>
<script>
import InputBlock from '@/components/InputBlock.vue';
import Textarea from '@/components/TextareaBlock.vue';
export default {
  name: 'Step3',
	components: {
		Textarea,
		InputBlock
	},
	props: ['formData'],
	// data() {
	// 	return {
	// 		formData: {
  //       question11: '',
  //     },
	// 	}
	// },
	methods: {
		stepPrevius() {
			this.$emit('prev-step')
		},
		validateStep() {
      // Перевірка полів кроку 2
      // if (this.formData.question11) {
        this.$emit('submit', this.formData);
      // } else {
      //   alert('Будь ласка, заповніть всі поля.');
      // }
    },
	},

}
</script>
<style lang="sass" scoped>
.formbox
	.formbox-title
		font-size: 14px
		font-weight: 600
		text-align: justify
.field:not(:last-child)
	margin-bottom: 25px
.buttongroup
	justify-content: space-between
</style>