<template>
	<div>
		<div class="block block-success">
			<div class="formbox">
				<h4 class="title">
					Дякуємо! За кілька хвилин Ваш відгук буде передано в опрацювання.
				</h4>
				<button class="button" @click="this.$router.go(0)">Заповнити ще</button>

			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: "Success"
}
</script>
<style lang="sass" scoped>
.title
	// color: #5a667b
	font-weight: 600
	font-size: 16px
	margin: 15px 0 25px 0
.block-success
	text-align: center
button
	// text-decoration: underline
	// background: none
	// border: none
	// box-shadow: none
	// cursor: pointer
	// color: #2980b9
.button
	padding: 10px 15px
	font-weight: 600
	appearance: none
	background: #bd4635
	color: #fff
	border: none
	// text-transform: uppercase
	border: 1px solid rgba(#bdc3c7, 0.25)
	border-radius: 5px
	margin: 0 5px
	box-shadow: 0 10px 15px 5px rgba(0,0,0,0.05)
	cursor: pointer
	text-decoration: none
	&:hover,
	&:focus
		background: linear-gradient(180deg,#9d392b 28.98%,#bd4635)
		-webkit-box-shadow: 0 2px 8px rgba(0,0,0,.25)
		box-shadow: 0 2px 8px rgba(0,0,0,.25)
		color: #fff
		text-decoration: none

</style>