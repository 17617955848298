<template>
	<div>
		<div class="block block-1">

			<div class="formbox">
				<h1 class="h1">Відгук про time+</h1>
				<div class="subtitle"></div>
				<RadioBlock
					label="Оцініть будь ласка Ваше самопочуття:<br> (Біль – інтенсивність від 1 до 10)"
					name="so1"
					v-model="formData.selectedOption1"
				>
				</RadioBlock>
				<!-- <Textarea label="Чи комфортно Вам було спілкуватись з нашим співробітником? 🙂🙂🙂" v-model="formData.comfort" :value="formData.comfort"></Textarea> -->

				<SelectBlock label="Чи задовольняє Вас інтенисивність процедури?"
					:labels="['Задовольняє', 'Хотів би сильніше', 'Занадто інтенсивно']"
					name="sintens"
					v-model="formData.sintens"
				 ></SelectBlock>
				 <SelectBlock label="Загальне самопочуття:"
					:labels="['Краще', 'Без змін', 'Гірше']"
					name="ssam"
					v-model="formData.ssam"
				 ></SelectBlock>
				<Textarea label="Якщо є питання або побажання напишіть нам будь ласка" v-model="formData.msg" :value="formData.msg"></Textarea>

				<div class="buttongroup">
					<button @click="validateStep">вперед</button>
				</div>

			</div>

		</div>





	</div>
</template>
<script>
import Textarea from '@/components/TextareaBlock.vue';
import Input from '@/components/InputBlock.vue';
import SelectBlock from '@/components/SelectBlock.vue';
import RadioBlock from '@/components/RadioBlock.vue';


export default {
  name: 'Step1',
	components: {
		Textarea,
		Input,
		SelectBlock,
		RadioBlock
	},

	props: ['formData'],
	data() {
		return {
			// formData: {

      // },
		}
	},
	methods: {
		validateStep() {
      // Перевірка полів кроку 2
      // if (this.formData.question1) {
        this.$emit('next-step', this.formData);
      // } else {
        // alert('Будь ласка, заповніть всі поля.');
      // }
    },
	}
}
</script>
<style lang="sass" scoped>
.buttongroup
	justify-content: flex-end
</style>