<template>
  <div>

		<router-view></router-view>

	</div>
</template>

<script>

</script>

<style lang="sass">
button
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none
button:focus
	outline: none

</style>
