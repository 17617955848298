<template>
	<div>
		<div class="progressbar">
			<div class="active" :style="mwidth" title="66%">1</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ProgressBar',
	props: ['activeBlock', 'count'],
	computed: {
		mwidth() {
			let cur = parseInt(this.activeBlock.slice(-1));
			let count = this.count
			let str = 'width:' + ( (cur)/count )*100 + '%;'
			return str;
		}
	}
}
</script>
<style lang="sass" scoped>
.progressbar
  position: fixed
  height: 7px
  background-color: #fff
  width: 100%
  bottom: 0
  left: 0
  z-index: 1
  .active
    background-color: #2ecc71
    width: 0%
</style>