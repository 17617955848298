<template>
	<div>
		<div class="main">
			<img src="@/assets/logo.svg" alt="logotimeplus">
			<a href="https://timeplus.ua/">відвідати сайт time+</a>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.main
	position: relative
	margin: 3em 0
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	img
		width: 150px
		margin-bottom: 25px
	a
		padding: 10px 15px
		font-weight: 600
		appearance: none
		background: #bd4635
		color: #fff
		border: none
		// text-transform: uppercase
		border: 1px solid rgba(#bdc3c7, 0.25)
		border-radius: 5px
		margin: 0 5px
		box-shadow: 0 10px 15px 5px rgba(0,0,0,0.05)
		cursor: pointer
		text-decoration: none
		&:hover,
		&:focus
			background: linear-gradient(180deg,#9d392b 28.98%,#bd4635)
			-webkit-box-shadow: 0 2px 8px rgba(0,0,0,.25)
			box-shadow: 0 2px 8px rgba(0,0,0,.25)
			color: #fff
			text-decoration: none
</style>