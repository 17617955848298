<template>
	<div class="field">
		<div class="label" v-html="formattedLabel"></div>
		<div class="group" >
			<div v-for="(lab, index) in labels" :key="index">

				<input class="checkbox-select" type="radio" :name="name" :id="name+index"

				:value="lab"
				v-model="selectValue"
				>
				<label :for="name+index"><span>{{ lab }}</span></label>

			</div>
			<!-- <div class="grad" v-for="(index) in 10" :key="index">

				<input class="checkbox-tools" type="radio" :name="name+'tools'" :id="name+'tool-'+index"

				:value="index"
				v-model="internalValue"
				>
				<label class="for-checkbox-tools" :for="name+'tool-'+index">
					{{ index }}
				</label>
			</div> -->
		</div>
		<span v-if="errorMessage" class="error">{{ errorMessage }}</span>


	</div>
</template>
<script>
export default {
  name: 'RadioBlock',
	props: {
		reqval: {
			type: Number
		},
		label: {
			type: String
		},
		name: {
			type: String
		},
    modelValue: {
      type: String,
      // required: true
    },
		errorMessage: String,
		labels: Array
  },
	emits: ['update:modelValue'],
	data() {
    return {
      selectValue: this.modelValue
    };
  },
  watch: {
    selectValue(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
	computed: {
    formattedLabel() {
      return this.label.replace(/\n/g, '<br>');
    },
  },
}
</script>
<style lang="sass" scoped>
.group
	display: flex
	gap: 10px
	flex-direction: column
	// width: 100%
	// display: grid
	// gap: 10px
	// grid-template-columns: repeat(auto-fit, minmax(50px, 1fr))
	// justify-content: start
// .grad
// 	display: block
// 	width: 50px
// 	height: 50px
// 	margin: 0 auto
.label
	font-size: 0.85em
	line-height: 1.2
	font-weight: 600
	margin-bottom: 10px
	// color: rgba(0,0,0, .6)
// 	span
// 		display: block
// 		color: #5a667b
.checkbox-select:not(:checked),
.checkbox-select:checked
	position: absolute
	left: -999999999px
	width: 0
	height: 0
	visibility: hidden
.checkbox-select:not(:checked) + label,
.checkbox-select:checked + label
	display: block
	width: 30px
	height: 30px
	background-color: tomato
	cursor: pointer
	line-height: 30px
	background-color: #ecf0f1
	place-items: center
	cursor: pointer
	border-radius: 3px
.checkbox-select:checked + label
	background-color: #2ecc71
.checkbox-select + label > span
	display: block
	position: absolute
	left: 65px
	font-size: 14px

.field
	margin-bottom: 35px
	&.error
		background-color: yellow
.error
	// display: none
	color: #e74c3c
	font-size: 14px
	&.active
		display: block
@media (max-width: 640px)
	.group
		text-align: center
		grid-template-columns: repeat(5, minmax(50px, 1fr))
		gap: 10px
</style>