<template>
	<div class="field">
		<div class="label" v-html="formattedLabel"></div>
		<div class="group">
			<div class="grad" v-for="(index) in 10" :key="index">

				<input class="checkbox-tools" type="radio" :name="name+'tools'" :id="name+'tool-'+index"

				:value="index"
				v-model="internalValue"
				>
				<label class="for-checkbox-tools" :for="name+'tool-'+index">
					{{ index }}
				</label>
			</div>
		</div>
		<span v-if="errorMessage" class="error">{{ errorMessage }}</span>


	</div>
</template>
<script>
export default {
  name: 'RadioBlock',
	props: {
		reqval: {
			type: Number
		},
		label: {
			type: String
		},
		name: {
			type: String
		},
    modelValue: {
      type: Number,
      // required: true
    },
		errorMessage: String
  },
	emits: ['update:modelValue'],
	data() {
    return {
      internalValue: this.modelValue
    };
  },
  watch: {
    internalValue(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
	computed: {
    formattedLabel() {
      return this.label.replace(/\n/g, '<br>');
    },
  },
}
</script>
<style lang="sass" scoped>
.group
	width: 100%
	display: grid
	gap: 10px
	grid-template-columns: repeat(auto-fit, minmax(50px, 1fr))
	justify-content: center
.grad
	display: block
	width: 50px
	height: 50px
	margin: 0 auto
.label
	font-size: 0.85em
	line-height: 1.2
	font-weight: 600
	margin-bottom: 10px
	// color: rgba(0,0,0, .6)
	span
		display: block
		color: #5a667b
.checkbox-tools:not(:checked),
.checkbox-tools:checked
	position: absolute
	left: -9999999px
	width: 0
	height: 0
	visibility: hidden
.checkbox-tools:not(:checked) + label,
.checkbox-tools:checked + label
	display: grid
	position: relative
	width: 50px
	height: 50px
	place-items: center
	cursor: pointer
	border-radius: 3px
.grad
	.checkbox-tools:not(:checked) + label
		background-color: #ecedf3

	&:first-child
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #e74c3c
	&:nth-child(2)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #e74c3c
	&:nth-child(3)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #e67e22
	&:nth-child(4)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #e67e22
	&:nth-child(5)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #fbc531
	&:nth-child(6)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #fbc531
	&:nth-child(7)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #2ecc71
	&:nth-child(8)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #2ecc71
	&:nth-child(9)
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #27ae60
	&:last-child
		.checkbox-tools:checked + label,
		.checkbox-tools:hover + label
			background-color: #27ae60

	.checkbox-tools:checked + label,
	.checkbox-tools:hover + label
		// background-image: linear-gradient(298deg, #e74c3c, #f39c12)
		// background-color: #2ecc71
		color: #fff
		font-weight: 700
.field
	margin-bottom: 35px
	&.error
		background-color: yellow
.error
	// display: none
	color: #e74c3c
	font-size: 14px
	&.active
		display: block
@media (max-width: 640px)
	.group
		text-align: center
		grid-template-columns: repeat(5, minmax(50px, 1fr))
		gap: 10px
</style>